.App {
    font-family: sans-serif;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

body {
    margin: 0;
    padding: 0;
}

.controls {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(100, 100, 100, 0.4);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-bottom-right-radius: 4px;
}

.controls h2 {
    margin: 5px 0;
    font-size: 18px;
}

.controls .title {
    margin-top: 15px;
    font-size: 17px;
}

.controls input {
    background-color: rgba(255, 255, 255, 0.6);
    border: none;
    border: 1px solid rgba(255, 255, 255, 0.6);
    margin-top: 4px;
    padding: 8px;
    transition: 0.2s ease;
}

.controls input:focus {
    outline: none;
    border: 1px solid lightseagreen;
}

.controls input.invalid {
    border: 1px solid red;
}

.graph {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
